import firebase from 'firebase'

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBcJ95iLy93VsRip6_-cTdV1fk79_vh1QM",
  authDomain: "macro-plaza.firebaseapp.com",
  databaseURL: "https://macro-plaza.firebaseio.com",
  projectId: "macro-plaza",
  storageBucket: "macro-plaza.appspot.com",
  messagingSenderId: "873794965087",
  appId: "1:873794965087:web:df4442b97537a28b97d5ea"
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const firestore = firebase.firestore();
export {
  firebase,
  auth,
  firestore,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};